exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-admin-gant-tsx": () => import("./../../../src/pages/admin/gant.tsx" /* webpackChunkName: "component---src-pages-admin-gant-tsx" */),
  "component---src-pages-admin-index-tsx": () => import("./../../../src/pages/admin/index.tsx" /* webpackChunkName: "component---src-pages-admin-index-tsx" */),
  "component---src-pages-admin-layout-tsx": () => import("./../../../src/pages/admin/layout.tsx" /* webpackChunkName: "component---src-pages-admin-layout-tsx" */),
  "component---src-pages-admin-orders-index-tsx": () => import("./../../../src/pages/admin/orders/index.tsx" /* webpackChunkName: "component---src-pages-admin-orders-index-tsx" */),
  "component---src-pages-admin-orders-next-days-tsx": () => import("./../../../src/pages/admin/orders/next-days.tsx" /* webpackChunkName: "component---src-pages-admin-orders-next-days-tsx" */),
  "component---src-pages-admin-products-index-tsx": () => import("./../../../src/pages/admin/products/index.tsx" /* webpackChunkName: "component---src-pages-admin-products-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-order-auth-tsx": () => import("./../../../src/pages/order/auth.tsx" /* webpackChunkName: "component---src-pages-order-auth-tsx" */),
  "component---src-pages-order-detail-[id]-tsx": () => import("./../../../src/pages/order/detail/[id].tsx" /* webpackChunkName: "component---src-pages-order-detail-[id]-tsx" */),
  "component---src-pages-order-index-tsx": () => import("./../../../src/pages/order/index.tsx" /* webpackChunkName: "component---src-pages-order-index-tsx" */),
  "component---src-pages-order-payment-tsx": () => import("./../../../src/pages/order/payment.tsx" /* webpackChunkName: "component---src-pages-order-payment-tsx" */),
  "component---src-pages-tepovac-branik-tsx": () => import("./../../../src/pages/tepovac/branik.tsx" /* webpackChunkName: "component---src-pages-tepovac-branik-tsx" */)
}

